body {
	background-color: #353535;
	color: #ddd;
}

h1, h2, h3, h4 {
	color: #ddd;
}
h1.fqn {
	border-bottom-color: #d2d2d2;
}
h2, h3, h4 {
	border-bottom-color: #d2d2d2;
}

.in-band {
	background-color: #353535;
}

.invisible {
	background: rgba(0, 0, 0, 0);
}

.docblock code, .docblock-short code {
	background-color: #2A2A2A;
}
pre, .rustdoc.source .example-wrap {
	background-color: #2A2A2A;
}

.sidebar, .mobile-topbar, .sidebar-menu-toggle {
	background-color: #505050;
}

.rust-logo {
	filter: drop-shadow(1px 0 0px #fff)
		drop-shadow(0 1px 0 #fff)
		drop-shadow(-1px 0 0 #fff)
		drop-shadow(0 -1px 0 #fff)
}

/* Improve the scrollbar display on firefox */
* {
	scrollbar-color: rgb(64, 65, 67) #717171;
}
.sidebar {
	scrollbar-color: rgba(32,34,37,.6) #5a5a5a;
}

/* Improve the scrollbar display on webkit-based browsers */
::-webkit-scrollbar-track {
	background-color: #717171;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(32, 34, 37, .6);
}
.sidebar::-webkit-scrollbar-track {
	background-color: #717171;
}
.sidebar::-webkit-scrollbar-thumb {
	background-color: rgba(32, 34, 37, .6);
}

.sidebar .current,
.sidebar a:hover {
	background: #444;
}

.source .sidebar {
	background-color: #565656;
}

.line-numbers span { color: #3B91E2; }
.line-numbers .line-highlighted {
	background-color: #0a042f !important;
}

.docblock h1, .docblock h2, .docblock h3, .docblock h4, .docblock h5, .docblock h6 {
	border-bottom-color: #DDD;
}

.docblock table td, .docblock table th {
	border-color: #ddd;
}

.content .method .where,
.content .fn .where,
.content .where.fmt-newline {
	color: #ddd;
}

.search-results a:hover {
	background-color: #777;
}

.search-results a:focus {
	color: #eee !important;
	background-color: #616161;
}
.search-results a:focus span { color: #eee !important; }
a.result-trait:focus { background-color: #013191; }
a.result-traitalias:focus { background-color: #013191; }
a.result-mod:focus,
a.result-externcrate:focus { background-color: #884719; }
a.result-enum:focus { background-color: #194e9f; }
a.result-struct:focus { background-color: #194e9f; }
a.result-union:focus { background-color: #194e9f; }
a.result-fn:focus,
a.result-method:focus,
a.result-tymethod:focus { background-color: #4950ed; }
a.result-type:focus { background-color: #194e9f; }
a.result-associatedtype:focus { background-color: #884719; }
a.result-foreigntype:focus { background-color: #194e9f; }
a.result-attr:focus,
a.result-derive:focus,
a.result-macro:focus { background-color: #217d1c; }
a.result-constant:focus,
a.result-static:focus { background-color: #884719; }
a.result-primitive:focus { background-color: #194e9f; }
a.result-keyword:focus { background-color: #884719; }

.content .item-info::before { color: #ccc; }

.content span.enum, .content a.enum, .block a.current.enum { color: #2dbfb8; }
.content span.struct, .content a.struct, .block a.current.struct { color: #2dbfb8; }
.content span.type, .content a.type, .block a.current.type { color: #2dbfb8; }
.content span.associatedtype,
.content a.associatedtype,
.block a.current.associatedtype { color: #D2991D; }
.content span.foreigntype, .content a.foreigntype, .block a.current.foreigntype { color: #2dbfb8; }
.content span.attr, .content a.attr, .block a.current.attr,
.content span.derive, .content a.derive, .block a.current.derive,
.content span.macro, .content a.macro, .block a.current.macro { color: #09bd00; }
.content span.union, .content a.union, .block a.current.union { color: #2dbfb8; }
.content span.constant, .content a.constant, .block a.current.constant,
.content span.static, .content a.static, .block a.current.static { color: #D2991D; }
.content span.primitive, .content a.primitive, .block a.current.primitive { color: #2dbfb8; }
.content span.externcrate,
.content span.mod, .content a.mod, .block a.current.mod { color: #D2991D; }
.content span.trait, .content a.trait, .block a.current.trait { color: #b78cf2; }
.content span.traitalias, .content a.traitalias, .block a.current.traitalias { color: #b78cf2; }
.content span.fn, .content a.fn, .block a.current.fn,
.content span.method, .content a.method, .block a.current.method,
.content span.tymethod, .content a.tymethod, .block a.current.tymethod,
.content .fnname{ color: #2BAB63; }
.content span.keyword, .content a.keyword, .block a.current.keyword { color: #D2991D; }

.sidebar a { color: #fdbf35; }
.sidebar a.current.enum { color: #12ece2; }
.sidebar a.current.struct { color: #12ece2; }
.sidebar a.current.type { color: #12ece2; }
.sidebar a.current.associatedtype { color: #fdbf35; }
.sidebar a.current.foreigntype { color: #12ece2; }
.sidebar a.current.attr,
.sidebar a.current.derive,
.sidebar a.current.macro { color: #0be900; }
.sidebar a.current.union { color: #12ece2; }
.sidebar a.current.constant
.sidebar a.current.static { color: #fdbf35; }
.sidebar a.current.primitive { color: #12ece2; }
.sidebar a.current.externcrate
.sidebar a.current.mod { color: #fdbf35; }
.sidebar a.current.trait { color: #cca7ff; }
.sidebar a.current.traitalias { color: #cca7ff; }
.sidebar a.current.fn,
.sidebar a.current.method,
.sidebar a.current.tymethod { color: #32d479; }
.sidebar a.current.keyword { color: #fdbf35; }

pre.rust .comment { color: #8d8d8b; }
pre.rust .doccomment { color: #8ca375; }

nav.main .current {
	border-top-color: #eee;
	border-bottom-color: #eee;
}
nav.main .separator {
	border-color: #eee;
}

a {
	color: #D2991D;
}

a#toggle-all-docs,
a.anchor,
.small-section-header a,
#source-sidebar a,
pre.rust a,
.sidebar h2 a,
.sidebar h3 a,
.mobile-topbar h2 a,
.in-band a {
	color: #ddd;
}
.search-results a {
	color: #ddd;
}
a.test-arrow {
	color: #dedede;
}
body.source .example-wrap pre.rust a {
	background: #333;
}

details.rustdoc-toggle > summary.hideme > span,
details.rustdoc-toggle > summary::before,
details.undocumented > summary::before {
	color: #999;
}

details.rustdoc-toggle > summary::before,
details.undocumented > summary::before {
	filter: invert(100%);
}

#crate-search, .search-input {
	color: #111;
	background-color: #f0f0f0;
	border-color: #000;
}

.search-input {
	border-color: #e0e0e0;
}

.search-input:focus {
	border-color: #008dfd;
}

.module-item .stab,
.import-item .stab {
	color: #ddd;
}

.stab.unstable { background: #FFF5D6; border-color: #FFC600; color: #2f2f2f; }
.stab.deprecated { background: #ffc4c4; border-color: #db7b7b; color: #2f2f2f; }
.stab.portability { background: #F3DFFF; border-color: #b07bdb; color: #2f2f2f; }
.stab.portability > code { background: none; }

#help > div {
	background: #4d4d4d;
	border-color: #bfbfbf;
}

#help span.bottom, #help span.top {
	border-color: #bfbfbf;
}

#help dt {
	border-color: #bfbfbf;
	background: rgba(0,0,0,0);
}

.rightside,
.out-of-band {
	color: grey;
}

.result-name .primitive > i, .result-name .keyword > i {
	color: #ddd;
}

.line-numbers :target { background-color: transparent; }

/* Code highlighting */
pre.rust .kw { color: #ab8ac1; }
pre.rust .kw-2, pre.rust .prelude-ty { color: #769acb; }
pre.rust .number, pre.rust .string { color: #83a300; }
pre.rust .self, pre.rust .bool-val, pre.rust .prelude-val,
pre.rust .attribute, pre.rust .attribute .ident { color: #ee6868; }
pre.rust .macro, pre.rust .macro-nonterminal { color: #3E999F; }
pre.rust .lifetime { color: #d97f26; }
pre.rust .question-mark {
	color: #ff9011;
}

.example-wrap > pre.line-number {
	border-color: #4a4949;
}

a.test-arrow {
	background-color: rgba(78, 139, 202, 0.2);
}

a.test-arrow:hover{
	background-color: #4e8bca;
}

.toggle-label,
.code-attribute {
	color: #999;
}

:target {
	background-color: #494a3d;
	border-right: 3px solid #bb7410;
}

pre.compile_fail {
	border-left: 2px solid rgba(255,0,0,.8);
}

pre.compile_fail:hover, .information:hover + pre.compile_fail {
	border-left: 2px solid #f00;
}

pre.should_panic {
	border-left: 2px solid rgba(255,0,0,.8);
}

pre.should_panic:hover, .information:hover + pre.should_panic {
	border-left: 2px solid #f00;
}

pre.ignore {
	border-left: 2px solid rgba(255,142,0,.6);
}

pre.ignore:hover, .information:hover + pre.ignore {
	border-left: 2px solid #ff9200;
}

.tooltip.compile_fail {
	color: rgba(255,0,0,.8);
}

.information > .compile_fail:hover {
	color: #f00;
}

.tooltip.should_panic {
	color: rgba(255,0,0,.8);
}

.information > .should_panic:hover {
	color: #f00;
}

.tooltip.ignore {
	color: rgba(255,142,0,.6);
}

.information > .ignore:hover {
	color: #ff9200;
}

.search-failed a {
	color: #0089ff;
}

.tooltip::after {
	background-color: #000;
	color: #fff;
	border-color: #000;
}

.tooltip::before {
	border-color: transparent black transparent transparent;
}

.notable-traits-tooltiptext {
	background-color: #111;
	border-color: #777;
}

.notable-traits-tooltiptext .notable {
	border-bottom-color: #d2d2d2;
}

#titles > button:not(.selected) {
	background-color: #252525;
	border-top-color: #252525;
}

#titles > button:hover, #titles > button.selected {
	border-top-color: #0089ff;
	background-color: #353535;
}

#titles > button > div.count {
	color: #888;
}

@media (max-width: 700px) {
	.sidebar-menu {
		background-color: #505050;
		border-bottom-color: #e0e0e0;
		border-right-color: #e0e0e0;
	}

	.sidebar-elems {
		background-color: #505050;
		border-right-color: #000;
	}

	#sidebar-filler {
		background-color: #505050;
		border-bottom-color: #e0e0e0;
	}
}

kbd {
	color: #000;
	background-color: #fafbfc;
	border-color: #d1d5da;
	border-bottom-color: #c6cbd1;
	box-shadow: inset 0 -1px 0 #c6cbd1;
}

#theme-picker, #settings-menu, #help-button {
	border-color: #e0e0e0;
	background: #f0f0f0;
	color: #000;
}

#theme-picker:hover, #theme-picker:focus,
#settings-menu:hover, #settings-menu:focus,
#help-button:hover, #help-button:focus {
	border-color: #ffb900;
}

#copy-path {
	color: #999;
}
#copy-path > img {
	filter: invert(50%);
}
#copy-path:hover > img {
	filter: invert(65%);
}

#theme-choices {
	border-color: #e0e0e0;
	background-color: #353535;
}

#theme-choices > button:not(:first-child) {
	border-top-color: #e0e0e0;
}

#theme-choices > button:hover, #theme-choices > button:focus {
	background-color: #4e4e4e;
}

@media (max-width: 700px) {
	#theme-picker {
		background: #f0f0f0;
	}
}

.search-results .result-name span.alias {
	color: #fff;
}
.search-results .result-name span.grey {
	color: #ccc;
}

#sidebar-toggle {
	background-color: #565656;
}
#sidebar-toggle:hover {
	background-color: #676767;
}
#source-sidebar {
	background-color: #565656;
}
#source-sidebar > .title {
	border-bottom-color: #ccc;
}
div.files > a:hover, div.name:hover {
	background-color: #444;
}
div.files > .selected {
	background-color: #333;
}
.setting-line > .title {
	border-bottom-color: #ddd;
}

.scraped-example .example-wrap .rust span.highlight {
	background: rgb(91, 59, 1);
}
.scraped-example .example-wrap .rust span.highlight.focus {
	background: rgb(124, 75, 15);
}
.scraped-example:not(.expanded) .code-wrapper:before {
	background: linear-gradient(to bottom, rgba(53, 53, 53, 1), rgba(53, 53, 53, 0));
}
.scraped-example:not(.expanded) .code-wrapper:after {
	background: linear-gradient(to top, rgba(53, 53, 53, 1), rgba(53, 53, 53, 0));
}
.toggle-line-inner {
	background: #616161;
}
.toggle-line:hover .toggle-line-inner {
	background: #898989;
}
